import { SetFoundShipment } from './receive-shipment.actions';
import { ReceiveShipmentModel } from './receive-shipment.model';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

@State<ReceiveShipmentModel>({
  name: 'receiveShipment',
})
@Injectable()
export class ReceiveShipmentState {
  @Action(SetFoundShipment)
  public setFoundShipment(ctx: StateContext<ReceiveShipmentModel>, data: ReceiveShipmentModel): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      ...data,
    });
  }
}
