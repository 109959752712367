import { Shipment } from '@core/models/shipment.model';

export class SetFoundShipment {
  public static readonly type = '[Receive shipment] Set order nr. and name';

  constructor(
    public order: string,
    public name: string,
    public shipment: Shipment,
  ) {}
}
